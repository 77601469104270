// React & Dependencies
import React from 'react';

import { shape, arrayOf, string, bool } from 'prop-types';

// RecommendationsFE Components
import { RecommendationsList, RecommendationsListSingle } from '@recommendations-fe/list';

import { RecommendationsCardVerticalTitleFirst } from '@recommendations-fe/card';
import IconFactory from '../recommendations-icons/icon-factory';

// Homes Components
import { DEVICE_TYPE, CARD_TYPE } from '../../../../utils/constants';

import Section from '../section';

const namespace = 'ui-recommendations-single';

const RecommendationsFeSingle = ({
  id,
  restClient,
  recommendation_data,
  bookmarks: bookmarkedItemsFromState,
  lazy = false,
  country,
  cardType,
  deviceType,
  ...rest
}) => (
  <Section className={`${namespace}-section`} testId={id}>
    <RecommendationsList
      id={id}
      IconFactory={IconFactory}
      Card={RecommendationsCardVerticalTitleFirst}
      ListComponent={RecommendationsListSingle}
      listType={CARD_TYPE.SINGLE}
      hideRebates
      apiresponse={recommendation_data}
      preload={!lazy}
      fetchPriority={!lazy && 'high'}
      criticalImages={1}
      lazy={lazy}
      deviceType={deviceType}
      {...rest}
      cardType={cardType}
      contextProps={{
        thousandSeparator: country?.thousands_separator,
        decimalSeparator: country?.decimal_separator,
      }}
    />
  </Section>
);

RecommendationsFeSingle.propTypes = {
  id: string,
  restClient: shape({}).isRequired,
  recommendation_data: shape({
    items: arrayOf(string),
  }),
  bookmarks: shape({}),
  deviceType: string,
  lazy: bool,
  country: shape({}),
  cardType: string,
};

RecommendationsFeSingle.defaultProps = {
  cardType: CARD_TYPE.SINGLE,
  deviceType: DEVICE_TYPE.MOBILE,
};

export default RecommendationsFeSingle;
